<template>
  <section id="dashboard" v-if="!ability.can('read', 'actionlist')">
    <div v-if="hasCPT && hasCPD">

      <b-row class="match-height">
        <b-col xl="6" md="6">
          <couse :data="data" />
        </b-col>
        <b-col xl="6" md="6">
          <cousecpd :data-c-p-d="dataCPD" />
        </b-col>
        <b-col xl="6" md="6">
          <linechart :data="dataClient" />
        </b-col>
        <b-col xl="6" md="6">
          <piechart :data="dataBankAc" :chartOptions="chartOptions" />
        </b-col>
        <b-col xl="6" md="6">
          <columnchart :data="dataColumn" />
        </b-col>
      </b-row>
    </div>

    <div v-else-if="hasCPT && !hasCPD">
      <b-row class="">
        <b-col v-if="hasCPT" xl="7" md="6">
          <couse :data="data" />
        </b-col>
        <b-col v-if="!hasCPT && hasCPD" xl="7" md="6">
          <cousecpd :data-c-p-d="dataCPD" />
        </b-col>
        <b-col xl="5" md="6">
          <linechart :data="dataClient" />
        </b-col>
        <b-col xl="7">
          <piechart :data="dataBankAc" :chartOptions="chartOptions" />
        </b-col>
        <b-col xl="5" md="6">
          <columnchart :data="dataColumn" />
        </b-col>
      </b-row>
    </div>

    <div v-else-if="!hasCPT && hasCPD">
      <b-row class="">
        <b-col v-if="hasCPT" xl="7" md="6">
          <couse :data="data" />
        </b-col>
        <b-col v-if="!hasCPT && hasCPD" xl="7" md="6">
          <cousecpd :data-c-p-d="dataCPD" />
        </b-col>
        <b-col xl="5" md="6">
          <linechart :data="dataClient" />
        </b-col>
        <b-col xl="7">
          <piechart :data="dataBankAc" :chartOptions="chartOptions" />
        </b-col>
        <b-col xl="5" md="6">
          <columnchart :data="dataColumn" />
        </b-col>
      </b-row>
    </div>

    <div v-else>
      <b-row class="">
        <b-col xl="6" md="6">
          <b-card class="card-statistics p-1" style="min-height: 200px;">
            <div class="d-flex justify-content-between">
              <h4 class="font-weight-bolder">Course Record</h4>
              <b-card-text class="update-text font-small-2 mb-0">
                <!-- Updated 1 day ago -->
              </b-card-text>
            </div>
            <b-card-body class="">
              <b-row class="info-wrapper">
                <b-col xl="12" class="">
                  <b-media>
                    <b-media-body class="my-auto color0">
                      <h4 class="font-weight-bolder mb-0" style="color: green;background-color: white;">
                        No Course Settings found.
                      </h4>
                      <b-card-text class="font-small-3 mb-0" style="color: green;background-color: white;">
                        Please find admin to set up the required course settings.
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="6" md="6">
          <linechart :data="dataClient" />
        </b-col>
        <b-col xl="6">
          <piechart :data="dataBankAc" :chartOptions="chartOptions" />
        </b-col>
        <b-col xl="6" md="6">
          <columnchart :data="dataColumn" />
        </b-col>
      </b-row>

    </div>
  </section>
  <section id="dashboard" v-else>


    <b-row class="match-height">
      <b-col xl="7" md="6">
        <b-row>
          <b-col xl="12">
            <couse :data="data" />
          </b-col>
          <b-col xl="12">
            <cousecpd :data-c-p-d="dataCPD" />
          </b-col>
        </b-row>
      </b-col>
      <b-col xl="5" md="6">
        <appointment />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col xl="7" md="6">
        <b-row class="match-height">
          <b-col xl="6" md="6">
            <linechart :data="dataClient" />
          </b-col>
          <b-col xl="6" md="6">
            <linechart :data="dataStaff" />
          </b-col>
          <b-col xl="12">
            <piechart :data="dataBankAc" :chartOptions="chartOptions" />
          </b-col>
        </b-row>
      </b-col>
      <b-col xl="5" md="6">
        <columnchart :data="dataColumn" />
      </b-col>
    </b-row>


  </section>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import couse from "./dash/couse.vue";
import cousecpd from "./dash/cousecpd.vue";
import appointment from "./dash/appointment.vue";
import linechart from "./dash/linechart.vue";
import piechart from "./dash/piechart.vue";
import columnchart from "./dash/columnchart.vue";
import VueApexCharts from "vue-apexcharts";
import ability from "@/libs/acl/ability";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    couse,
    cousecpd,
    appointment,
    linechart,
    piechart,
    columnchart,
  },
  data() {
    return {
      hasCPT: true,
      hasCPD: true,
      dataClient: {
        series: [
          {
            name: "Clients",
            data: [0],
          },
        ],
        title: "No. of Clients",
        num: "",
        lineColor: "#94C8BB",
      },
      dataStaff: {
        series: [
          {
            name: "Staff",
            data: [0],
          },
        ],
        title: "No. of Staffs",
        num: "",
        lineColor: "#64A4B4",
      },

      dataBankAc: {
        series: [],
        title: "No. of Bank Accounts",
        num: "",
        text: {
          p1: "xxxxx",
          p2: "xxxxxxxxxxx",
        },
        // lineColor:'#013E79'
      },
      dataColumn: {
        series: [
          {
            name: "Annual Return",
            data: [0, 0, 0],
          },
          {
            name: "Completion",
            data: [0, 0, 0],
          },
        ],
        title: "Staff Annual Return Due and Completion",
        num: 0,
      },
      data: [
        {
          customClass: "",
          icon: "ClockIcon",
          color: "#01573E",
          title: "",
          subtitle: "",
        },
        {
          customClass: "",
          icon: "ClockIcon",
          color: "#01573E",
          title: "",
          subtitle: "",
        },
        {
          customClass: "",
          icon: "AlertTriangleIcon",
          color: "#01573E",
          title: "",
          subtitle: "",
        },
        {
          customClass: "",
          icon: "AlertTriangleIcon",
          color: "#01573E",
          title: "",
          subtitle: "",
        },
      ],
      dataCPD: [
        {
          customClass: "",
          icon: "ClockIcon",
          color: "#01573E",
          title: "",
          subtitle: "",
        },
        {
          customClass: "",
          icon: "ClockIcon",
          color: "#01573E",
          title: "",
          subtitle: "",
        },
        {
          customClass: "",
          icon: "AlertTriangleIcon",
          color: "#01573E",
          title: "",
          subtitle: "",
        },
        {
          customClass: "",
          icon: "AlertTriangleIcon",
          color: "#01573E",
          title: "",
          subtitle: "",
        },
      ],
      chartOptions: {
        chart: {
          type: "donut",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: { show: true },
        comparedResult: [2, -3, 8],
        labels: ["", "", ""],
        stroke: { width: 0 },
        // colors: [$earningsStrokeColor2, $earningsStrokeColor3, $themeColors.success],
        grid: {
          padding: {
            right: -20,
            bottom: -8,
            left: -20,
          },
        },
        plotOptions: {
          pie: {
            // startAngle: -10,
            donut: {
              labels: {
                show: true,
                value: {
                  offsetY: -9,
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseInt(val)}`;
                  },
                },
                total: {
                  show: true,
                  label: "Total",
                  //   formatter() {
                  //     return "53%";
                  //   },
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 1325,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 1200,
            options: {
              chart: {
                height: 120,
              },
            },
          },
          {
            breakpoint: 1045,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 120,
              },
            },
          },
        ],
      },
    };
  },
  methods: {
    fetchDashboardInfo(){
      store
        .dispatch("app/fetchDashboardInfo")
        .then((response) => {
          this.dataStaff.series[0].data = Object.values(response.data.user);
          this.dataStaff.num = response.data.total_users;
          this.dataClient.series[0].data = Object.values(response.data.clients);
          this.dataClient.num = response.data.total_clients;
          this.chartOptions.labels = Object.keys(response.data.bank_accounts);
          this.dataBankAc.series = Object.values(response.data.bank_accounts);
          this.dataBankAc.num = response.data.total_bank_accounts;
        })
        .catch((error) => {
          console.log(error);
        });


    },
    fetchDashboardStaff() {
      store
        .dispatch("app/fetchDashboardStaff")
        .then((response) => {
          this.dataStaff.series[0].data = Object.values(response.data.user);
          this.dataStaff.num = response.data.total_users;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchDashboardClient() {
      store
        .dispatch("app/fetchDashboardClient")
        .then((response) => {
          this.dataClient.series[0].data = Object.values(response.data.clients);
          this.dataClient.num = response.data.total_clients;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchDashboardBanks() {
      store
        .dispatch("app/fetchDashboardBanks")
        .then((response) => {
          console.log(this.chartOptions.plotOptions);
          this.chartOptions.labels = Object.keys(response.data.bank_accounts);
          this.dataBankAc.series = Object.values(response.data.bank_accounts);
          this.dataBankAc.num = response.data.total_clients;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchAnnualReturn() {
      store
        .dispatch("app/fetchAnnualReturn")
        .then((response) => {
          console.log(response)
          const currYearCPT = response.data.CPT.filter(
            (x) => x.year == new Date().getFullYear()
          );
          const currYearCPD = response.data.CPD.filter(
            (x) => x.year == new Date().getFullYear()
          );
          const CPTarray = [0, 0, 0];
          const CPDarray = [0, 0, 0];

          // get current month
          const currMonth = new Date().getMonth() + 1;
          //get previous month and upcoming month from current month
          const prevMonth = currMonth - 1;
          const nextMonth = currMonth + 1;

          currYearCPT.map((x) => {
            switch (x.month) {
              case prevMonth:
                CPTarray[0] = x.ids;
                break;
              case currMonth:
                CPTarray[1] = x.ids;
                break;
              case nextMonth:
                CPTarray[2] = x.ids;
                break;
              default:
                break;
            }
          });
          currYearCPD.map((x) => {
            switch (x.month) {
              case prevMonth:
                CPDarray[0] = x.ids;
                break;
              case currMonth:
                CPDarray[1] = x.ids;
                break;
              case nextMonth:
                CPDarray[2] = x.ids;
                break;
              default:
                break;
            }
          });
          this.dataColumn.series[0].data = CPTarray;
          this.dataColumn.series[1].data = CPDarray;
          this.dataColumn.num = CPTarray.length; // for change trigger
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchDashboardCourse() {
      store
        .dispatch("app/fetchDashboardCourse")
        .then((response) => {
          console.log(response);

          if (response.data.course.length == 0) {
            this.hasCPT = false;
          }

          // for rm / user
          if (response.data.course.length == 1) {
            const totalEthicsHours = parseFloat(response.data.course[0].min_ethics_hours);

            const hoursHolder = [];
            response.data.course[0].records.map((x) => hoursHolder.push(parseFloat(x.counted_hours)));
            const totalCompletedHours = hoursHolder.reduce(
              (prev, curr) => prev + curr,
              0
            );
            const ethicsHours = [];
            response.data.course[0].records.map((x) => {
              if (x.ethics_compliance == 1) {
                ethicsHours.push(parseFloat(x.counted_hours));
              }
            });
            const totalCompletedEthicsHours = ethicsHours.reduce(
              (prev, curr) => prev + curr,
              0
            );

            this.data[0].title = totalCompletedHours + " hrs";
            this.data[0].subtitle = "Total hours";
            this.data[1].subtitle = "Outstanding CPT hours";
            if (totalCompletedEthicsHours >= totalEthicsHours) {
              this.data[2].subtitle = "Required Ethics hours fulfilled";
              this.data[2].title = "PASS";
            } else {
              this.data[2].subtitle = "Outstanding Ethics hours";
              this.data[2].title = totalEthicsHours - totalCompletedEthicsHours + " hrs";
              this.data[2].color = "#ff3333";
            }
            if (totalCompletedHours >= response.data.course[0].min_hours) {
              this.data[1].title = "0 hrs";
              this.data[3].title = "PASS";
              this.data[3].subtitle = "Required hours fulfilled";
            } else {
              this.data[1].title =
                response.data.course[0].min_hours - totalCompletedHours + " hrs";
              this.data[3].title = "FAIL";
              this.data[3].subtitle = "Required hours not fulfilled";
              this.data[3].color = "#ff3333";
              this.data[3].customClass = "primary";
            }
          } else {
            // for ceo / admin

            const failedCourse = [];
            const failedEthics = [];

            response.data.course.map((course) => {
              const min_hours = parseFloat(course.min_hours);
              const min_ethics_hours = parseFloat(course.min_ethics_hours);

              if (course.records.length != 0) {
                const counted_hours_array = [];
                const counted_ethics_array = [];

                course.records.forEach((record) => {
                  if (record.ethics_compliance == 1) {
                    counted_hours_array.push(parseFloat(record.counted_hours));
                    counted_ethics_array.push(parseFloat(record.counted_hours));
                  } else {
                    counted_hours_array.push(parseFloat(record.counted_hours));
                  }
                });

                const userTotalHrs = counted_hours_array.reduce(
                  (prev, curr) => prev + curr,
                  0
                );
                console.log(userTotalHrs);
                const userTotalEthicsHrs = counted_ethics_array.reduce(
                  (prev, curr) => prev + curr,
                  0
                );
                console.log(userTotalEthicsHrs);

                if (userTotalHrs < min_hours) {
                  failedCourse.push({
                    staff: course.name,
                    outstanding_hours: min_hours - userTotalHrs,
                  });
                }

                if (userTotalEthicsHrs < min_ethics_hours) {
                  failedEthics.push({
                    staff: course.name,
                    outstanding_ethics_hours: min_ethics_hours - userTotalEthicsHrs,
                  });
                }
              } else {
                failedEthics.push("failed");
                failedCourse.push("failed");
              }
            });

            console.log("failedCourse", failedCourse);
            console.log("failedEthics", failedEthics);

            if (failedCourse.length == 0) {
              this.data[3].title = "PASS";
              this.data[3].subtitle = "Required hours fulfilled";
            } else {
              this.data[3].title = "FAIL";
              this.data[3].subtitle = "Required hours not fulfilled";
              this.data[3].color = "#ff3333";
            }

            if (failedEthics.length == 0) {
              this.data[2].title = "PASS";
              this.data[2].subtitle = "Required Ethics hours fulfilled";
            } else {
              this.data[2].title = "FAIL";
              this.data[2].subtitle = "Required Ethics hours not fulfilled";
              this.data[2].color = "#ff3333";
            }

            // all course records in one array
            const newResponse = [];
            response.data.course.map((allCourse) => {
              allCourse.records.map((records) => {
                newResponse.push(records);
              });
            });

            console.log(newResponse);

            const hoursHolder = [];
            newResponse.map((x) => hoursHolder.push(parseFloat(x.counted_hours)));
            const totalCompletedHours = hoursHolder.reduce(
              (prev, curr) => prev + curr,
              0
            );
            const ethicshoursHolder = [];
            newResponse.map((x) => {
              if (x.ethics_compliance == 1) {
                ethicshoursHolder.push(parseFloat(x.counted_hours));
              }
            });
            // const totalCompletedEthicsHours = ethicshoursHolder.reduce(
            //   (prev, curr) => prev + curr,
            //   0
            // );

            this.data[0].title = totalCompletedHours + " hrs";
            this.data[0].subtitle = "Total hours";
            const minhourarray = [];
            response.data.course.map((course) => minhourarray.push(parseFloat(course.min_hours)));

            const minhour = minhourarray.reduce((prev, curr) => prev + curr, 0);

            // const minethicshourarray = [];
            // response.data.course.map((course) => minethicshourarray.push(course.min_ethics_hours));

            // const minEthicshour = minethicshourarray.reduce((prev, curr) => prev + curr, 0);

            // if (totalCompletedEthicsHours >= minEthicshour) {
            //   this.data[2].title = "PASS";
            //   this.data[2].subtitle = "Required Ethics hours fulfilled";
            // } else {
            //   this.data[2].title = "FAIL";
            //   this.data[2].subtitle = "Required Ethics hours not fulfilled";
            //   this.data[2].color = "#ff3333";
            // }

            if (totalCompletedHours >= minhour) {
              this.data[1].title = "0 hrs";
              this.data[1].subtitle = "Outstanding CPT hours";
              // this.data[3].title = "PASS";
              // this.data[3].subtitle = "Required hours fulfilled";
            } else {
              this.data[1].title = minhour - totalCompletedHours + " hrs";
              this.data[1].subtitle = "Outstanding CPT hours";
              // this.data[3].title = "FAIL";
              // this.data[3].subtitle = "Required hours not fulfilled";
              // this.data[3].color = "#ff3333";
            }

            if (newResponse.length == 0 && response.data.course.length != 0) {
              this.data[3].title = "FAIL";
              this.data[3].subtitle = "Required hours not fulfilled";
              this.data[3].color = "#ff3333";
              this.data[2].title = "FAIL";
              this.data[2].subtitle = "Required Ethics hours not fulfilled";
              this.data[2].color = "#ff3333";
            } else if (response.data.course.length == 0) {
              this.data[3].title = "PASS";
              this.data[3].subtitle = "Required hours fulfilled";
              this.data[2].title = "PASS";
              this.data[2].subtitle = "Required Ethics hours fulfilled";
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // another copy for cpd records
    fetchDashboardCourseForCPD() {
      store
        .dispatch("app/fetchDashboardCourseForCPD")
        .then((response) => {
          console.log(response);

          if (response.data.course.length == 0) {
            this.hasCPD = false;
          }

          // for rm / user
          if (response.data.course.length == 1) {
            const totalEthicsHours = parseFloat(response.data.course[0].min_ethics_hours);

            const hoursHolder = [];
            response.data.course[0].records.map((x) => hoursHolder.push(parseFloat(x.counted_hours)));
            const totalCompletedHours = hoursHolder.reduce(
              (prev, curr) => prev + curr,
              0
            );
            const ethicsHours = [];
            response.data.course[0].records.map((x) => {
              if (x.ethics_compliance == 1) {
                ethicsHours.push(parseFloat(x.counted_hours));
              }
            });
            const totalCompletedEthicsHours = ethicsHours.reduce(
              (prev, curr) => prev + curr,
              0
            );

            this.dataCPD[0].title = totalCompletedHours + " hrs";
            this.dataCPD[0].subtitle = "Total hours";
            this.dataCPD[1].subtitle = "Outstanding CPD hours";
            if (totalCompletedEthicsHours >= totalEthicsHours) {
              this.dataCPD[2].subtitle = "Required Ethics hours fulfilled";
              this.dataCPD[2].title = "PASS";
            } else {
              this.dataCPD[2].subtitle = "Outstanding Ethics hours";
              this.dataCPD[2].title = totalEthicsHours - totalCompletedEthicsHours + " hrs";
              this.dataCPD[2].color = "#ff3333";
            }
            if (totalCompletedHours >= response.data.course[0].min_hours) {
              this.dataCPD[1].title = "0 hrs";
              this.dataCPD[3].title = "PASS";
              this.dataCPD[3].subtitle = "Required hours fulfilled";
            } else {
              this.dataCPD[1].title =
                response.data.course[0].min_hours - totalCompletedHours + " hrs";
              this.dataCPD[3].title = "FAIL";
              this.dataCPD[3].subtitle = "Required hours not fulfilled";
              this.dataCPD[3].color = "#ff3333";
              this.dataCPD[3].customClass = "primary";
            }
          } else {
            // for ceo / admin

            const failedCourse = [];
            const failedEthics = [];

            response.data.course.map((course) => {
              const min_hours = parseFloat(course.min_hours);
              const min_ethics_hours = parseFloat(course.min_ethics_hours);

              if (course.records.length != 0) {
                const counted_hours_array = [];
                const counted_ethics_array = [];

                course.records.forEach((record) => {
                  if (record.ethics_compliance == 1) {
                    counted_hours_array.push(parseFloat(record.counted_hours));
                    counted_ethics_array.push(parseFloat(record.counted_hours));
                  } else {
                    counted_hours_array.push(parseFloat(record.counted_hours));
                  }
                });

                const userTotalHrs = counted_hours_array.reduce(
                  (prev, curr) => prev + curr,
                  0
                );
                console.log(userTotalHrs);
                const userTotalEthicsHrs = counted_ethics_array.reduce(
                  (prev, curr) => prev + curr,
                  0
                );
                console.log(userTotalEthicsHrs);

                if (userTotalHrs < min_hours) {
                  failedCourse.push({
                    staff: course.name,
                    outstanding_hours: min_hours - userTotalHrs,
                  });
                }

                if (userTotalEthicsHrs < min_ethics_hours) {
                  failedEthics.push({
                    staff: course.name,
                    outstanding_ethics_hours: min_ethics_hours - userTotalEthicsHrs,
                  });
                }
              } else {
                failedEthics.push("failed");
                failedCourse.push("failed");
              }
            });

            console.log("failedCourse", failedCourse);
            console.log("failedEthics", failedEthics);

            if (failedCourse.length == 0) {
              this.dataCPD[3].title = "PASS";
              this.dataCPD[3].subtitle = "Required hours fulfilled";
            } else {
              this.dataCPD[3].title = "FAIL";
              this.dataCPD[3].subtitle = "Required hours not fulfilled";
              this.dataCPD[3].color = "#ff3333";
            }

            if (failedEthics.length == 0) {
              this.dataCPD[2].title = "PASS";
              this.dataCPD[2].subtitle = "Required Ethics hours fulfilled";
            } else {
              this.dataCPD[2].title = "FAIL";
              this.dataCPD[2].subtitle = "Required Ethics hours not fulfilled";
              this.dataCPD[2].color = "#ff3333";
            }

            // all course records in one array
            const newResponse = [];
            response.data.course.map((allCourse) => {
              allCourse.records.map((records) => {
                newResponse.push(records);
              });
            });

            console.log(newResponse);

            const hoursHolder = [];
            newResponse.map((x) => hoursHolder.push(parseFloat(x.counted_hours)));
            const totalCompletedHours = hoursHolder.reduce(
              (prev, curr) => prev + curr,
              0
            );
            const ethicshoursHolder = [];
            newResponse.map((x) => {
              if (x.ethics_compliance == 1) {
                ethicshoursHolder.push(parseFloat(x.counted_hours));
              }
            });
            // const totalCompletedEthicsHours = ethicshoursHolder.reduce(
            //   (prev, curr) => prev + curr,
            //   0
            // );

            this.dataCPD[0].title = totalCompletedHours + " hrs";
            this.dataCPD[0].subtitle = "Total hours";
            const minhourarray = [];
            response.data.course.map((course) => minhourarray.push(parseFloat(course.min_hours)));

            const minhour = minhourarray.reduce((prev, curr) => prev + curr, 0);

            // const minethicshourarray = [];
            // response.data.course.map((course) => minethicshourarray.push(course.min_ethics_hours));

            // const minEthicshour = minethicshourarray.reduce((prev, curr) => prev + curr, 0);

            // if (totalCompletedEthicsHours >= minEthicshour) {
            //   this.dataCPD[2].title = "PASS";
            //   this.dataCPD[2].subtitle = "Required Ethics hours fulfilled";
            // } else {
            //   this.dataCPD[2].title = "FAIL";
            //   this.dataCPD[2].subtitle = "Required Ethics hours not fulfilled";
            //   this.dataCPD[2].color = "#ff3333";
            // }

            if (totalCompletedHours >= minhour) {
              this.dataCPD[1].title = "0 hrs";
              this.dataCPD[1].subtitle = "Outstanding CPD hours";
              // this.dataCPD[3].title = "PASS";
              // this.dataCPD[3].subtitle = "Required hours fulfilled";
            } else {
              this.dataCPD[1].title = minhour - totalCompletedHours + " hrs";
              this.dataCPD[1].subtitle = "Outstanding CPD hours";
              // this.dataCPD[3].title = "FAIL";
              // this.dataCPD[3].subtitle = "Required hours not fulfilled";
              // this.dataCPD[3].color = "#ff3333";
            }

            if (newResponse.length == 0 && response.data.course.length != 0) {
              this.dataCPD[3].title = "FAIL";
              this.dataCPD[3].subtitle = "Required hours not fulfilled";
              this.dataCPD[3].color = "#ff3333";
              this.dataCPD[2].title = "FAIL";
              this.dataCPD[2].subtitle = "Required Ethics hours not fulfilled";
              this.dataCPD[2].color = "#ff3333";
            } else if (response.data.course.length == 0) {
              this.dataCPD[3].title = "PASS";
              this.dataCPD[3].subtitle = "Required hours fulfilled";
              this.dataCPD[2].title = "PASS";
              this.dataCPD[2].subtitle = "Required Ethics hours fulfilled";
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    /* this.fetchDashboardStaff();
    this.fetchDashboardClient();
    this.fetchDashboardBanks();
     */
    this.fetchDashboardInfo();
     this.fetchAnnualReturn();
    this.fetchDashboardCourse();
    this.fetchDashboardCourseForCPD();
  },
  setup() {
    return {
      ability,
    };
  },
};
</script>

<style lang="scss" scoped>
.primary {
  color: red;
}
</style>
